import { Button } from '@mui/material';
import { FVInitParams, JanusResponse } from 'neurotec-faceverification-management-client';
import React, { useCallback, useContext, useLayoutEffect, useRef, useState } from 'react'
import { connectToJanus, setupDataChannel, setupWebRTC, stopSession } from '../../helpers/webRTC';
import { AppContext } from '../../store/context';
import { removeSession, showMessage, showOperationSummary, showNav, setOptions, removeQrSession } from '../../store/reducer';
import OperationSummary from '../OperationSummary/OperationSummary';
import jsQR from "jsqr";
import VerifyFromQrCode from '../Verify/QrCode/VerifyFromQrCode';
import { Buffer } from 'buffer';

const constraints = {
    audio: false,
    video: {
        frameRate: { max: 30 },
        width: { ideal: 1280 },
        height: { ideal: 720 }
    }
};

const VideoStream: React.FC = () => {

    const videoRef = useRef<HTMLVideoElement>(null);
    const [frontMode, setFrontMode] = useState<boolean>(true)
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const rtcConnection = useRef<RTCPeerConnection | null>(null);
    const [showControlls, setShowControlls] = useState(false);
    const [showQrVerification, setShowQrVerification] = useState(false);
    const { state, dispatch } = useContext(AppContext)
    const qrId = useRef<number>(0);

    const setupCanvas = useCallback(() => {
        if (videoRef.current && canvasRef.current) {
            let canvas = canvasRef.current;
            canvas.hidden = false;
            let video = videoRef.current;
            canvas.width = video.clientWidth;
            canvas.height = video.clientHeight;
            canvas.style.top = video.offsetTop + "px";
            canvas.style.left = video.offsetLeft + "px";
        }
    }, []);

    const setupVideo = useCallback(async (frontFacing: boolean) => {
        if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({...constraints, video: {facingMode: frontFacing ? "user" : "environment", ...constraints.video}})
                    video.addEventListener("loadeddata", setupCanvas);
                    video.srcObject = stream;
                    if (frontFacing || (stream.getTracks()[0].getCapabilities()?.facingMode?.[0] === "environment" && !frontFacing))
                        setFrontMode(frontFacing)
                } catch (error) {
                    console.error(error);
                }
            } else {
                dispatch(showMessage({ message: "WebRTC not available", type: "error" }));
            }
        }
    }, [dispatch, setupCanvas]);

    const closeSession = useCallback(() => {
        console.debug("Closing sessions start");
        stopSession(state.session, rtcConnection.current).then(setupCanvas);
        dispatch(removeSession());
        setShowControlls(false);
        rtcConnection.current = null;
        console.debug("Closing sessions");
    }, [dispatch, rtcConnection, state.session, setupCanvas]);

    const closeQrSession = useCallback(() => {
        console.debug("Closing QR sessions start");
        cancelAnimationFrame(qrId.current)
        dispatch(removeQrSession());
        setupVideo(true).then(setupCanvas);
        setShowControlls(false);
        console.debug("Closing QR sessions");
    }, [setupCanvas, dispatch, setupVideo])

    const handleCancel = async () => {
        if (state.session)
            closeSession()
        if (state.qrSession) {
            closeQrSession()
        }
    }

    const onSuccessCallback = useCallback((connection: JanusResponse | undefined) => {
        console.debug("success callback")
        const operationId = connection?.operation_id ? connection.operation_id : "";
        dispatch(showOperationSummary(operationId))
    }, [dispatch]);

    const setupBiometricStream = useCallback(async () => {
        if (state.session && videoRef.current && canvasRef.current) {
            let video = videoRef.current;
            let canvas = canvasRef.current;
            let stream: MediaStream = video.srcObject as MediaStream;
            if (stream) {
                try {
                    dispatch(showMessage({ message: "Loading...", type: "info" }));
                    setShowControlls(true);
                    let janusConnection: JanusResponse | undefined;
                    rtcConnection.current = await setupWebRTC(state.session, stream);
                    await setupDataChannel(
                        rtcConnection.current,
                        state.session,
                        video,
                        canvas,
                        (message) => { dispatch(showMessage(message)) },
                        () => closeSession(),
                        () => onSuccessCallback(janusConnection),
                        state.options?.livenessMode);
                    janusConnection = await connectToJanus(rtcConnection.current, state.session, state.options as FVInitParams)
                    if (!janusConnection) {
                        dispatch(showMessage({ message: "Unable to setup connection", type: "error" }));
                        closeSession();
                        return;
                    }
                    dispatch(showNav(false));
                } catch (err: any) {
                    closeSession();
                    let errorMessage: string = "Unable to start session";
                    if (err.isAxiosError && err.response.data) {
                        errorMessage = err.response.data.message
                    }
                    console.log(errorMessage);
                    dispatch(showMessage({ message: errorMessage, type: "error" }));
                }
            } else {
                closeSession();
                dispatch(showMessage({ message: "No stream available", type: "error" }));
            }
        }
    }, [closeSession, dispatch, onSuccessCallback, state.options, state.session]);

    const runQrReaderStream = useCallback(async (canvas: HTMLCanvasElement, video: HTMLVideoElement) => {
        let ctx = canvas.getContext("2d", { willReadFrequently: true });
        if (ctx) {
            canvas.hidden = true;
            ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
            let dat = ctx.getImageData(0, 0, canvas.width, canvas.height);
            dispatch(showMessage({message: "Looking for QR code", type: "info"}))
            if (dat) {
                const code = jsQR(dat.data, dat?.width, dat?.height);  

                if (code && code.binaryData.length > 0 && checkIfTemplateValid(code.binaryData)) {
                    closeQrSession()
                    let u8 = new Uint8Array(code.binaryData);
                    let b64 = Buffer.from(u8).toString('base64');
                    dispatch(setOptions({verificationTemplate: b64}))
                    setShowQrVerification(true)
                    return;
                }
            }       
        }

        if (state.qrSession)
            qrId.current = requestAnimationFrame(() => runQrReaderStream(canvas, video))
    }, [state.qrSession, qrId, closeQrSession, dispatch])

    const checkIfTemplateValid = (template: number[]): boolean => {
        if (template[0] === "F".charCodeAt(0) && template[1] === "V".charCodeAt(0))
            return true;
        return false
    }

    const setupQrReaderStream = useCallback(async () => {
        if (state.qrSession && videoRef.current && canvasRef.current) {            
            let video = videoRef.current;
            let canvas = canvasRef.current;
            let stream: MediaStream = video.srcObject as MediaStream;
            if (stream) {
                try {
                    dispatch(showNav(false));
                    qrId.current = requestAnimationFrame(() => runQrReaderStream(canvas, video));
                    setShowControlls(true)
                } catch (err: any) {
                    closeQrSession()
                    let errorMessage: string = "Unable to start session";
                    console.log(errorMessage);
                    dispatch(showMessage({ message: errorMessage, type: "error" }));
                }
            } else {
                closeQrSession()
                dispatch(showMessage({ message: "No stream available", type: "error" }));
            }
        }
    }, [runQrReaderStream, dispatch, closeQrSession, state.qrSession, qrId])

    useLayoutEffect(() => {
        if (videoRef.current && !videoRef.current.srcObject)
            setupVideo(true);
        if (state.session && !state.showNav)
            setupVideo(true)
            setupBiometricStream()
        if (state.qrSession && !state.showNav) {
            setupVideo(false).then(setupQrReaderStream)            
        }

        setupCanvas();
        window.addEventListener("resize", setupCanvas);
        return () => window.removeEventListener("resize", setupCanvas);
    }, [setupBiometricStream, setupCanvas, setupVideo, setupQrReaderStream, state.showNav, state.session, state.qrSession])

    return (
        <React.Fragment>
            <video ref={videoRef} autoPlay muted playsInline style={{ width: "100%", height: "auto", transform: frontMode ? "scaleX(-1)" : "scaleX(1)"}} />
            <canvas ref={canvasRef} style={{ position: "absolute", zIndex: 2, top: 0, left: 0, transform: "scaleX(-1)" }} />
            {showControlls ? (<Button variant="contained" sx={{ position: "fixed", bottom: "2rem", right: "2rem", zIndex: 3 }} onClick={handleCancel}>Cancel</Button>) : null}
            <OperationSummary />
            <VerifyFromQrCode show={showQrVerification} setShow={setShowQrVerification}/>
        </React.Fragment>
    )
}

export default VideoStream
