import React, { useContext, useReducer, useState } from 'react'
import { Button, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FVAdvancedInitParams, FVInitParams } from 'neurotec-faceverification-management-client'
import { NavigationContext } from '../../components/Navigation/store/context'
import { closePage } from '../../components/Navigation/store/reducer'
import { fvInitParamsStorage } from '../../config/const'
import { initialFvParameters, useLocalStorage } from '../../helpers/useLocalStorage'
import { LivenessMode } from '../../types/FaceVerification'
import CustomTable from './CustomTable'
import { optionsReducer } from './reducer'
import { AppContext } from '../../store/context'
import { setOptions as setAppOptions } from '../../store/reducer'


const Options: React.FC = () => {

    const { dispatch } = useContext(NavigationContext);
    const appDispatch = useContext(AppContext).dispatch;
    const [options, setOptions] = useLocalStorage<FVInitParams>(fvInitParamsStorage, initialFvParameters);
    const [newOptions, setNewOptions] = useState<FVInitParams>(options);
    const [advancedParams, dispatchAdvancedParams] = useReducer(optionsReducer, options.advancedParameters as FVAdvancedInitParams[]);

    const getEnumKeyValue = (e: any) => {
        var keys = Object.keys(e).filter(x => isNaN(Number(x)));
        var values = keys.map(x => [x, e[x as keyof typeof e]]);
        return values;
    }

    const save = () => {
        let _options = { ...newOptions, advancedParameters: advancedParams };
        setOptions(_options);
        appDispatch(setAppOptions(_options));
        dispatch(closePage())
    }

    const legacyReplaceAll = (str: string, find: string, replace: string) => {
        return str.replace(new RegExp(legacyEscapeRegExp(find), 'g'), replace);
    }

    const legacyEscapeRegExp = (string: string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    return (
        <React.Fragment>
            <DialogTitle>Face Verification Options</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ display: "inline-flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
                        <Typography
                            fontWeight={500}
                            align="right"
                            sx={{ width: "100%" }}
                        >
                            Check ICAO
                        </Typography>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={newOptions.checkIcaoCompliance}
                            onChange={(event) => setNewOptions(prev => ({ ...prev, checkIcaoCompliance: event.target.value === "true" }))}
                        >
                            <MenuItem key="true" value={"true"}>True</MenuItem>
                            <MenuItem key="false" value={"false"}>False</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "inline-flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
                        <Typography
                            fontWeight={500}
                            align="right"
                            sx={{ width: "100%" }}
                        >
                            Timeout
                        </Typography>
                        <TextField
                            fullWidth
                            type="number"
                            size="small"
                            value={newOptions.timeout}
                            onChange={(event) => setNewOptions(prev => ({ ...prev, timeout: parseInt(event.target.value) }))}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "inline-flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
                        <Typography
                            fontWeight={500}
                            align="right"
                            sx={{ width: "100%" }}
                        >
                            Liveness mode
                        </Typography>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={newOptions.livenessMode}
                            onChange={(event) => setNewOptions(prev => ({ ...prev, livenessMode: parseInt(event.target.value) }))}
                        >
                            {getEnumKeyValue(LivenessMode).map((x: LivenessMode[]) =>
                                <MenuItem key={x[0]} value={x[1]} >
                                    {legacyReplaceAll(x[0].toString(), "_", " ")}
                                </MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTable fvAdvancedParams={advancedParams} dispatch={dispatchAdvancedParams} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(closePage())}>
                    Close
                </Button>
                <Box style={{ flex: '1 0 0' }} />
                <Button variant="contained" onClick={save}>
                    Save
                </Button>
            </DialogActions>
        </React.Fragment>
    )
}

export default Options
