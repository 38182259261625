import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { SubjectsApiClearDbRequest } from "neurotec-faceverification-management-client";
import React, { useContext, useEffect, useState } from "react";
import { NavigationContext } from "../../components/Navigation/store/context";
import { closePage } from "../../components/Navigation/store/reducer";
import { SubjectsAPI } from "../../config/management-api";
import { getManagementInfo } from "../../helpers/startOperation";
import { AppContext } from "../../store/context";
import { showMessage } from "../../store/reducer";
import { IManagementInfo } from "../../types/FaceVerification";


interface IClearDBProps {
    prefix: string
}

const ClearDB: React.FC<IClearDBProps> = (props) => {

    const { dispatch } = useContext(NavigationContext);
    const appDispatch = useContext(AppContext).dispatch;
    const [state, setState] = useState<IManagementInfo | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const managementInfo = await getManagementInfo();
                if (managementInfo) {
                    setState(managementInfo);
                    return;
                } else {
                    dispatch(closePage());
                    appDispatch(showMessage({ message: "Unable to parse management info", type: "error" }))
                }
            } catch (err) {
                console.error(err);
                dispatch(closePage());
                appDispatch(showMessage({ message: "Unable to load management info", type: "error" }))
            }
        }
        fetchData();
    }, [appDispatch, dispatch])

    const clearDb = async () => {
        if (state?.prefixMode === false) {
            dispatch(closePage());
            appDispatch(showMessage({ message: "Clear DB can only be done from management page", type: "warning" }));
            return;
        }
        const request: SubjectsApiClearDbRequest = {
            prefix: state?.prefixMode ? props.prefix : undefined
        }
        SubjectsAPI.clearDb(request).then(res => {
            appDispatch(showMessage({ message: "DB cleared successfully", type: "info" }));
        }).catch(err => {
            console.error(err);
            appDispatch(showMessage({ message: "Unable to clear DB", type: "error" }))
        })
        dispatch(closePage());
    }

    return (
        <React.Fragment>
            <DialogTitle>Clear database</DialogTitle>
            <DialogContent>
                This action will clear all data associated with your unique session prefix
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(closePage())}>
                    Cancel
                </Button>
                <Box style={{ flex: '1 0 0' }} />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={clearDb}
                    disabled={!state?.allowClearDb}
                >
                    Clear
                </Button>
            </DialogActions>
        </React.Fragment>
    )
};

export default ClearDB;