import React from "react";

export interface NavigationState {
    page: React.FC<any> | null,
    selected: boolean,
    isDrawerOpen: boolean,
    isMobile: boolean,
}
export const initNavigationState: NavigationState = {
    page: null,
    selected: false,
    isDrawerOpen: false,
    isMobile: false
}