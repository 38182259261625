import { Button, DialogActions, DialogContent, DialogTitle, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { NavigationContext } from '../../components/Navigation/store/context'
import { closePage } from '../../components/Navigation/store/reducer'
import { fvPrefixStorage } from '../../config/const'
import { generateSubjectPrefix } from '../../helpers/prefixGenerator'
import { getManagementInfo } from '../../helpers/startOperation'
import { useLocalStorage } from '../../helpers/useLocalStorage'
import { AppContext } from '../../store/context'
import { showMessage } from '../../store/reducer'
import { IManagementInfo } from '../../types/FaceVerification'

const Info: React.FC = () => {

    const { dispatch } = useContext(NavigationContext);
    const appDispatch = useContext(AppContext).dispatch;
    const [state, setState] = useState<IManagementInfo | null>(null);
    const [prefix] = useLocalStorage(fvPrefixStorage, generateSubjectPrefix());

    useEffect(() => {
        const fetchData = async () => {
            try {
                const managementInfo = await getManagementInfo();
                if (managementInfo) {
                    setState(managementInfo);
                    return;
                } else {
                    dispatch(closePage());
                    appDispatch(showMessage({ message: "Unable to parse management info", type: "error" }))
                }
            } catch (err) {
                console.error(err);
                dispatch(closePage());
                appDispatch(showMessage({ message: "Unable to load management info", type: "error" }))
            }
        }
        fetchData();
    }, [appDispatch, dispatch])

    return (
        <React.Fragment>
            <DialogTitle>Management information</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Allow settings override</TableCell><TableCell>{state?.allowSettingsOverride ? "true" : "false"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Allow clear DB</TableCell><TableCell>{state?.allowClearDb ? "true" : "false"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Management version</TableCell><TableCell>{state?.version}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Management revision</TableCell><TableCell>{state?.revision.substring(0, 8)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Prefix mode enabled</TableCell><TableCell>{state?.prefixMode ? "true" : "false"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Prefix</TableCell><TableCell>{state?.prefixMode ? prefix : "none"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Contacts</TableCell><TableCell><Link href="mailto:faceverification@neurotechnology.com">faceverification@neurotechnology.com</Link></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(closePage())}>
                    Close
                </Button>
                <Box style={{ flex: '1 0 0' }} />
            </DialogActions>
        </React.Fragment>
    )
}

export default Info
