import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2A5A50"
        },
        secondary: {
            main: "#3B9C87"
        },
        background: {
            default: "#E5E5E5",
            paper: "#FBFDFC"
        }
    }
});

export default theme;