import React from "react";

export enum ActionType {
    ClickMenu,
    ToggleDrawer,
    ToggleMobileMenu,
    ClosePage
}

export interface ClickMenu {
    type: ActionType.ClickMenu,
    page: React.FC<any>
}

export interface ToggleDrawer {
    type: ActionType.ToggleDrawer
}

export interface ToggleMobileMenu {
    type: ActionType.ToggleMobileMenu,
    toggle: boolean
}

export interface ClosePage {
    type: ActionType.ClosePage,
}

export type NavigationActions = ClickMenu | ToggleDrawer | ToggleMobileMenu | ClosePage;