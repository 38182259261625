import { FVInitParams, SessionData } from "neurotec-faceverification-management-client";
import { AddSession, AppActions, AppActionType, IMessage, AddQrSession, RemoveSession, SetOptions, ShowMessage, ShowNavigation, ShowOperationSummary, ShowQrCode, RemoveQrSession } from "./actions";
import { AppState } from "./state";

export const appReducer = (state: AppState, action: AppActions): AppState => {
    switch (action.type) {
        case AppActionType.AddSession:
            return { ...state, session: action.session, options: action.fvInitParameters, showNav: false }
        case AppActionType.RemoveSession:
            return { ...state, session: null, showNav: true }
        case AppActionType.ShowMessage:
            if (state.message.message === action.message.message)
                return state;
            return { ...state, message: action.message }
        case AppActionType.ShowNavigation:
            return { ...state, showNav: action.show }
        case AppActionType.SetOptions:
            return { ...state, options: action.options }
        case AppActionType.ShowOperationSummary:
            return { ...state, showOperationSummary: action.operationId }
        case AppActionType.ShowQrCode:
            return { ...state, showQrCode: action.subjectId }
        case AppActionType.AddQrSession:
            return { ...state, qrSession: true, showNav: false }
        case AppActionType.RemoveQrSession:
            return { ...state, qrSession: false, showNav: true }
        default:
            return state;
    }
}

export const addSession = (session: SessionData | null, fvInitParameters: FVInitParams | null): AddSession => {
    return {
        type: AppActionType.AddSession,
        session,
        fvInitParameters
    }
}

export const removeSession = (): RemoveSession => {
    return {
        type: AppActionType.RemoveSession,
    }
}

export const setOptions = (options: FVInitParams): SetOptions => {
    return {
        type: AppActionType.SetOptions,
        options
    }
}

export const showMessage = (message: IMessage): ShowMessage => {
    return {
        type: AppActionType.ShowMessage,
        message
    }
}

export const showNav = (show: boolean): ShowNavigation => {
    return {
        type: AppActionType.ShowNavigation,
        show
    }
}
export const showOperationSummary = (operationId: string): ShowOperationSummary => {
    return {
        type: AppActionType.ShowOperationSummary,
        operationId
    }
}

export const showQrCode = (subjectId: string): ShowQrCode => {
    return {
        type: AppActionType.ShowQrCode,
        subjectId
    }
}

export const addQrSession = (): AddQrSession => {
    return {
        type: AppActionType.AddQrSession,
    }
}

export const removeQrSession = (): RemoveQrSession => {
    return {
        type: AppActionType.RemoveQrSession,
    }
}
