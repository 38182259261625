import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Subject, SubjectStatus, Image } from 'neurotec-faceverification-management-client'
import React, { useContext, useEffect, useState } from 'react'
import { ImagesAPI, SubjectsAPI } from '../../config/management-api'
import { AppContext } from '../../store/context'
import { showOperationSummary } from '../../store/reducer'
import SubjectImage from './SubjectImage'

const OperationSummary: React.FC = (props) => {

    const { state, dispatch } = useContext(AppContext)
    const [subjectInfo, setSubjectInfo] = useState<Subject>()
    const [imageInfo, setImageInfo] = useState<Image>()
    const [showRect, setShowRect] = useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            if (state.showOperationSummary.length > 0) {
                const subject = (await SubjectsAPI.getSubjectByOperationId({ uuid: state.showOperationSummary })).data
                const image = (await ImagesAPI.getImageByUUID({ uuid: state.showOperationSummary })).data
                setSubjectInfo(subject);
                setImageInfo(image)
                image.tokenImage ? setShowRect(false) : setShowRect(true)
            }
        }
        fetchData();
    }, [state.showOperationSummary])


    const getSubjectImage = () => {
        if (imageInfo) {
            if (imageInfo.tokenImage) {
                return imageInfo.tokenImage as unknown as string;
            }
            if (imageInfo.image) {
                return imageInfo.image as unknown as string;
            }
        }
        return undefined;
    }

    const getImageRect = () => {
        if (subjectInfo && subjectInfo.boundingRect) {
            return subjectInfo.boundingRect;
        }
        return {};
    }

    const getColor = () => {
        if (subjectInfo)
            switch (subjectInfo.status) {
                case SubjectStatus.Success:
                    return "green";
                case SubjectStatus.None:
                    return "red";
                default:
                    return "green";
            }
        else return "yellow"
    }

    const close = () => {
        setSubjectInfo(undefined);
        dispatch(showOperationSummary(""));
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            close()
        }  
    }

    return (
        <Dialog
            open={state.showOperationSummary.length > 0}
            onClose={close}
            fullWidth={true}
            onKeyDown={handleKeyDown}
        >
            <DialogTitle>Operation summary</DialogTitle>
            <DialogContent>
                {subjectInfo ? (
                    <React.Fragment>
                        <Box sx={{ backgroundColor: getColor(), width: "100%", textAlign: "center", fontSize: "1.5rem", color: "white", fontWeight: "400" }} >{subjectInfo? (subjectInfo.status? subjectInfo.status : "Success") : null}</Box>
                        <SubjectImage base64Image={getSubjectImage()} rectangle={getImageRect()} showRect={showRect} />
                        {
                        subjectInfo.age && subjectInfo.age > 0 && subjectInfo.age <= 100?
                        <Grid style={{backgroundColor: getColor(),  borderRadius: '4px'}} >
                            <Typography style={{marginTop: "5px", paddingTop: "2px", paddingBottom: "2px", color: "white"}} align='center'>
                                {"Estimated age: " + subjectInfo.age}
                            </Typography>
                        </Grid>
                        : null
                        }
                    </React.Fragment>
                ) : (
                    <CircularProgress />)}
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default OperationSummary
