import React from "react";
import { ActionType, ClickMenu, ClosePage, NavigationActions } from "./actions";
import { NavigationState } from "./state";

export const navigationReducer = (state: NavigationState, action: NavigationActions): NavigationState => {
    switch (action.type) {
        case ActionType.ClickMenu:
            return { ...state, page: action.page, selected: true, isDrawerOpen: false };
        case ActionType.ToggleDrawer:
            return { ...state, isDrawerOpen: !state.isDrawerOpen };
        case ActionType.ToggleMobileMenu:
            return { ...state, isMobile: action.toggle };
        case ActionType.ClosePage:
            return { ...state, selected: false };
        default:
            return state;
    }
}

export const selectMenu = (page: React.FC<any>): ClickMenu => {
    return {
        type: ActionType.ClickMenu,
        page: page
    }
};

export const closePage = (): ClosePage => {
    return {
        type: ActionType.ClosePage
    }
};
