import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../store/context";
import { SubjectsAPI } from "../../../config/management-api";
import { showQrCode } from "../../../store/reducer";
import QrCodeImage from "./QrCodeImage";

const QrCode: React.FC = () => {

    const { state, dispatch } = useContext(AppContext)
    const [qrCode, setQrCode] = useState<string>("")

    useEffect(() => {
        const fetchData = async () => {
            if (state.showQrCode.length > 0) {
                const data = (await SubjectsAPI.getSubjectsQRCodeBySubjectId({subjectid: state.showQrCode})).data.qrcode
                if (data)
                    setQrCode(data);
            }
        }
        fetchData();
    }, [state.showQrCode])

    const close = () => {
        setQrCode("");
        dispatch(showQrCode(""));
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            close()
        }  
    }
    
    return (
        <Dialog
            open={state.showQrCode.length > 0}
            onClose={close}
            fullWidth={true}
            onKeyDown={handleKeyDown}
        >
            <DialogTitle>Subject's QR code</DialogTitle>
            <DialogContent>
                {qrCode ? (
                    <React.Fragment>
                        <QrCodeImage base64Image={qrCode} />
                    </React.Fragment>
                ) : (
                    <CircularProgress />)}
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog >
    );
}

export default QrCode;