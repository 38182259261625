import { FVAdvancedInitParams } from "neurotec-faceverification-management-client";
import { NewOption, OptionActions, OptionsActionType, RemoveOption, UpdateOption } from "./actions";

export const optionsReducer = (state: FVAdvancedInitParams[], action: OptionActions) => {
    switch (action.type) {
        case OptionsActionType.NewOption:
            return [...state, { key: "", value: "" }];;
        case OptionsActionType.RemoveOption:
            return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
        case OptionsActionType.UpdateOption:
            if (action.option === "key") {
                state[action.index].key = action.value
            } else {
                state[action.index].value = action.value
            }
            return state;
        default:
            return state;
    }
}

export const newOption = (): NewOption => {
    return {
        type: OptionsActionType.NewOption
    }
}
export const removeOption = (index: number): RemoveOption => {
    return {
        type: OptionsActionType.RemoveOption,
        index
    }
}
export const updateOption = (index: number, option: "key" | "value", value: string): UpdateOption => {
    return {
        type: OptionsActionType.UpdateOption,
        value,
        index,
        option
    }
}