import React, { useContext, useEffect, useState } from 'react'
import { Autocomplete, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { FVInitParams, SubjectId, SubjectsApiGetSubjectsIdListRequest } from 'neurotec-faceverification-management-client'
import { NavigationContext } from '../../components/Navigation/store/context'
import { closePage } from '../../components/Navigation/store/reducer'
import { fvInitParamsStorage, fvPrefixStorage } from '../../config/const'
import { initialFvParameters, useLocalStorage } from '../../helpers/useLocalStorage'
import { startSession } from '../../helpers/webRTC'
import { AppContext } from '../../store/context'
import { addQrSession, addSession, showMessage, showQrCode } from '../../store/reducer'
import { Operations } from '../../types/FaceVerification'
import { SubjectsAPI } from '../../config/management-api'
import { generateSubjectPrefix } from '../../helpers/prefixGenerator'
import { getInitParamsByManagementConfig, getManagementInfo } from '../../helpers/startOperation'
import QrCode from './QrCode/QrCode'

export enum VerifyOptions {
    VERIFY = "VERIFY",
    VERIFY_QR = "VERIFY_QR",
    SHOW_QR = "SHOW_QR",
};

const Verify: React.FC = () => {

    const { dispatch } = useContext(NavigationContext);
    const appDispatch = useContext(AppContext).dispatch;

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const [verifyOption, setVerifyOption] = useState<VerifyOptions>(VerifyOptions.VERIFY);
    const [subjectIds, setSubjectIds] = useState<SubjectId[]>([])
    const [prefixMode, setPrefixMode] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState<string>("");
    const [options] = useLocalStorage<FVInitParams>(fvInitParamsStorage, initialFvParameters);
    const [prefix] = useLocalStorage(fvPrefixStorage, generateSubjectPrefix());

    useEffect(() => {
        const fetchData = async () => {
            const managementInfo = await getManagementInfo();
            const request: SubjectsApiGetSubjectsIdListRequest = {
                prefix: managementInfo?.prefixMode ? prefix : undefined
            }
            const subjectIds = (await SubjectsAPI.getSubjectsIdList(request)).data;
            setPrefixMode(managementInfo?.prefixMode ? true : false);
            setSubjectIds(subjectIds);
        }
        fetchData();
    }, [prefix])

    const handleSubjectChange = (subject: string | null | undefined) => {
        if (subject) 
            setSelectedSubject(subject);
        else {
            setError("")
            setSelectedSubject("")
        }
    }

    const handleButtonPress = () => {
        switch(verifyOption) {
            case VerifyOptions.VERIFY:
                verify();
                break;
            case VerifyOptions.VERIFY_QR:
                verifyQrCode();
                break;
            case VerifyOptions.SHOW_QR:
                showQR();
                break;
            default:
                break;
        }
    }

    const verify = async () => {
        if (!selectedSubject) {
            setError("Subject id must be selected")
            return;
        }
        if (subjectIds.find(x => (prefixMode ? x.id?.replace(prefix + ".", "") : x) === selectedSubject) === undefined) {
            setError("Subject id wasn't found")
            return
        }
        setLoading(true);
        const managementInfo = await getManagementInfo();
        if (!managementInfo) {
            setLoading(false);
            return;
        }
        let initParams: FVInitParams = getInitParamsByManagementConfig(managementInfo, options);
        initParams.subjectId = prefixMode? prefix + "." + selectedSubject : selectedSubject;
        initParams.type = Operations.VERIFY;
        startSession(Operations.VERIFY, initParams).then(res => {
            appDispatch(addSession(res.data, initParams));
        }).catch(err => {
            console.log(err)
            appDispatch(showMessage({ message: "Unable to create session: " + err.message, type: "error" }))
        }).finally(() => {
            dispatch(closePage())
        });
    }

    const verifyQrCode = async () => {
        dispatch(closePage())
        appDispatch(addQrSession());
    }

    const showQR = async () => {
        appDispatch(showQrCode((prefixMode? prefix + "." + selectedSubject : selectedSubject)));
    }

    const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVerifyOption(VerifyOptions[event.target.value as keyof typeof VerifyOptions])
    }

    const showSubjectIdsSelection = () => {
        return(
            <Autocomplete
                id="subject-select"
                freeSolo
                options={subjectIds.map(x => {
                    return (prefixMode ? (x as string).replace(prefix + ".", "") : (x as string))
                })}
                ListboxProps={{ style: { maxHeight: '20rem' } }}
                value={selectedSubject}
                onChange={(event: any, newValue: string | null | undefined) => { handleSubjectChange(newValue) }}
                autoHighlight
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error ? true : false}
                        helperText={error}
                        onChange={(event) => handleSubjectChange(event.target.value)}
                        autoFocus
                        required
                        margin="none"
                        label="Choose a Subject ID"
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        );
    }

    return (
        <React.Fragment>
            <QrCode />
            <DialogTitle>Verification method</DialogTitle>
            <DialogContent>
                <FormControl style={{width: "100%"}}>
                    <RadioGroup
                        aria-labelledby="verify-radio-buttons-group-label"
                        defaultValue={VerifyOptions.VERIFY}
                        name="verify-radio-buttons-group"
                        onChange={handleRadioButtonChange}
                    >
                        <FormControlLabel value={VerifyOptions.VERIFY} control={<Radio />} label="Verify from camera" />
                        <FormControlLabel value={VerifyOptions.VERIFY_QR} control={<Radio />} label="Verify from QR Code" />
                        <FormControlLabel value={VerifyOptions.SHOW_QR} control={<Radio />} label="Show QR Code" />
                        {verifyOption === VerifyOptions.VERIFY || verifyOption === VerifyOptions.SHOW_QR? showSubjectIdsSelection(): null}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(closePage())}>
                    Close
                </Button>
                <Box style={{ flex: '1 0 0' }} />
                {loading ? (<CircularProgress size="32px" sx={{ marginRight: 2 }} />)
                    :
                    (<Button
                        variant="contained"
                        color="primary"
                        onClick={handleButtonPress}
                    >
                        {verifyOption === VerifyOptions.VERIFY || verifyOption === VerifyOptions.VERIFY_QR? "Start" : "Show"}
                    </Button>)}
            </DialogActions>
        </React.Fragment>
    )
}

export default Verify
