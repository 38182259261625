import React, { useContext, useMemo, useReducer } from 'react'
import { Box, Dialog, Grid, IconButton, styled, Theme, useMediaQuery } from '@mui/material'
import Menu from '@mui/icons-material/Menu';
import Logo from '../Logo/Logo'
import MobileNavigation from './MobileNavigation';
import { NavigationContext } from './store/context';
import { closePage, navigationReducer } from './store/reducer';
import { initNavigationState } from './store/state';
import { ActionType } from './store/actions';
import { AppContext } from '../../store/context';

const ReactiveLogoContainer = styled(Grid)(({ theme }) => ({
    height: "inherit",
    padding: "0.5rem 0",
    flex: 1
}));

const ReactiveMenuContainer = styled(Grid)(({ theme }) => ({
    height: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly"
}));

const ReactiveNavigationContent = styled(Grid)(({ theme }) => ({
    height: "inherit",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    [theme.breakpoints.up("lg")]: {
        padding: "0 4.5rem"
    },
    [theme.breakpoints.down("lg")]: {
        padding: "0 2.5rem"
    },
    [theme.breakpoints.down("md")]: {
        padding: "0 1.5rem"
    },
    [theme.breakpoints.down("sm")]: {
        padding: "0"
    }
}));

const ReactiveNavigationBar = styled(Box)(({ theme }) => ({
    height: "80px",
    width: "100%",
    zIndex: 5,
    top: 0,
    position: "sticky",
    background: "#2F7868",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    [theme.breakpoints.down("sm")]: {
        height: "60px"
    }
}));

const Navigation: React.FC = (props) => {

    const appState = useContext(AppContext).state;

    const [state, dispatch] = useReducer(navigationReducer, initNavigationState);
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    useMemo(() => dispatch({ type: ActionType.ToggleMobileMenu, toggle: matches }), [matches])

    const navPicker = () => {
        if (state.isMobile) {
            return (
                <IconButton sx={{ color: "white" }} onClick={() => dispatch({ type: ActionType.ToggleDrawer })}>
                    <Menu fontSize="large" />
                </IconButton>
            )
        } else {
            return (
                <React.Fragment>
                    {props.children}
                </React.Fragment>
            )
        }
    }

    return (
        <NavigationContext.Provider value={{ state, dispatch }}>
            {state.isDrawerOpen ? (
                <MobileNavigation onNavClose={() => dispatch({ type: ActionType.ToggleDrawer })}>
                    {props.children}
                </MobileNavigation>) : null}
            <ReactiveNavigationBar sx={{ display: appState.showNav ? "block" : "none" }}>
                <ReactiveNavigationContent container spacing={0}>
                    <ReactiveLogoContainer item>
                        <Logo nav />
                    </ReactiveLogoContainer>
                    <ReactiveMenuContainer item>
                        {navPicker()}
                    </ReactiveMenuContainer>
                </ReactiveNavigationContent>
            </ReactiveNavigationBar>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={state.selected}
                onClose={() => dispatch(closePage())}
            >
                {state.page ? <state.page /> : null}
            </Dialog>
        </NavigationContext.Provider>
    )
}

export default Navigation