import { FVInitParams, SessionData } from "neurotec-faceverification-management-client";


export enum AppActionType {
    AddSession,
    RemoveSession,
    ShowMessage,
    ShowNavigation,
    ShowOperationSummary,
    ShowQrCode,
    AddQrSession,
    RemoveQrSession,
    SetOptions
}

export interface IMessage {
    message: string,
    type: "info" | "error" | "success" | "warning";
}

export interface AddSession {
    type: AppActionType.AddSession,
    session: SessionData | null,
    fvInitParameters: FVInitParams | null
}

export interface RemoveSession {
    type: AppActionType.RemoveSession
}

export interface ShowMessage {
    type: AppActionType.ShowMessage,
    message: IMessage
}

export interface ShowOperationSummary {
    type: AppActionType.ShowOperationSummary,
    operationId: string
}

export interface ShowQrCode {
    type: AppActionType.ShowQrCode,
    subjectId: string
}

export interface AddQrSession {
    type: AppActionType.AddQrSession,
}

export interface RemoveQrSession {
    type: AppActionType.RemoveQrSession,
}

export interface ShowNavigation {
    type: AppActionType.ShowNavigation,
    show: boolean
}

export interface SetOptions {
    type: AppActionType.SetOptions,
    options: FVInitParams
}

export type AppActions = AddSession | RemoveSession | ShowMessage | ShowOperationSummary | ShowQrCode | AddQrSession | RemoveQrSession | ShowNavigation | SetOptions;