import React from 'react'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, styled, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow, TextField } from '@mui/material';
import { FVAdvancedInitParams } from 'neurotec-faceverification-management-client';
import { OptionActions } from './actions';
import { newOption, removeOption, updateOption } from './reducer';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#2F7868",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        backgroundColor: theme.palette.common.white,
        border: 0,
    },
}));

export interface ICustomTableProps {
    fvAdvancedParams: FVAdvancedInitParams[] | undefined,
    dispatch: React.Dispatch<OptionActions>
}

const CustomTable: React.FC<ICustomTableProps> = (props) => {
    return (
        <Table aria-label="customized table" size="small">
            <TableHead>
                <TableRow>
                    <StyledTableCell>Key</StyledTableCell>
                    <StyledTableCell align="right">Value</StyledTableCell>
                    <StyledTableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {props.fvAdvancedParams?.map((row, index) => {
                    return (
                        <StyledTableRow key={row.key === "" ? Math.random().toString() : row.key}>
                            <StyledTableCell component="th" scope="row">
                                <TextField
                                    fullWidth
                                    size="small"
                                    defaultValue={row.key}
                                    onChange={(event) =>
                                        props.dispatch(
                                            updateOption(index, "key", event.target.value))
                                    }
                                />
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <TextField
                                    fullWidth
                                    size="small"
                                    defaultValue={row.value}
                                    onChange={(event) =>
                                        props.dispatch(updateOption(index, "value", event.target.value))
                                    }
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <IconButton
                                    color="error"
                                    onClick={() => props.dispatch(removeOption(index))}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </StyledTableCell>
                        </StyledTableRow>
                    )
                })}
                <StyledTableRow key="add-row">
                    <StyledTableCell component="th" scope="row" colSpan={3} sx={{ textAlign: "center", verticalAlign: "middle" }}>
                        <IconButton
                            color="primary"
                            onClick={() => props.dispatch(newOption())}
                        >
                            <AddBoxOutlinedIcon />
                        </IconButton>
                    </StyledTableCell>
                </StyledTableRow>
            </TableBody>
        </Table>
    )
}

export default CustomTable;
