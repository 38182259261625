import { Box, Typography } from '@mui/material'
import { Rectangle } from 'neurotec-faceverification-management-client';
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'


interface ISubjectImageProps {
    base64Image: string | undefined,
    rectangle: Rectangle,
    showRect: boolean,
}

const SubjectImage: React.FC<ISubjectImageProps> = (props) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const divRef = useRef<HTMLElement>(null);

    const clearAndDraw = () => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            ctx?.clearRect(0, 0, canvas.width, canvas.height);
            draw();
        }
    }

    const draw = useCallback(() => {
        if (canvasRef.current && divRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            if (ctx && props.base64Image) {
                const image = new Image();
                image.src = "data:image/png;base64, " + props.base64Image;
                image.onload = () => {
                    // Setting image width/heigth scale
                    const aspectRatio = image.width / image.height;

                    canvas.width = image.width;
                    canvas.height = image.height;

                    let canvasCurrentWidth = canvas.width;
                    let canvasCurrentHeight = canvas.height;

                    if (aspectRatio > 1) { //image is in landscape
                        canvasCurrentWidth = canvasCurrentHeight * aspectRatio;
                        canvasCurrentHeight = canvasCurrentWidth / aspectRatio;
                    }
                    else { // image is in portrait
                        canvasCurrentWidth *= (image.width / canvasCurrentWidth);
                        canvasCurrentHeight *= (image.height / canvasCurrentHeight);
                    }

                    const horizontalCenter = (canvas.width - canvasCurrentWidth) / 2;
                    const verticalCenter = (canvas.height - canvasCurrentHeight) / 2;

                    ctx.clearRect(0, 0, canvas.width, canvas.height)

                    ctx.drawImage(image, horizontalCenter, verticalCenter, canvasCurrentWidth, canvasCurrentHeight);
                    if (props.showRect) {
                        ctx.strokeStyle = "green"
                        ctx.beginPath();
                        ctx.lineWidth = 5
                        const rect = props.rectangle;
                        const widthProportion = image.width / canvasCurrentWidth;
                        const heightProportion = image.height / canvasCurrentHeight;
                        if (rect.x && rect.y && rect.width && rect.height)
                            ctx.rect(
                                (rect.x / widthProportion) + horizontalCenter,
                                (rect.y / heightProportion) + verticalCenter,
                                rect.width / widthProportion,
                                rect.height / heightProportion);
                        ctx.stroke()
                    }
                }
            }
        }
    }, [props]);

    useEffect(() => {
        clearAndDraw();
    });

    useLayoutEffect(() => {
        draw();
        window.addEventListener('resize', draw);
        return () =>
            window.removeEventListener('resize', draw);
    }, [draw])

    return (
        <Box ref={divRef} sx={{ display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", width: "100%" }}>
            {props.base64Image ?
                <canvas ref={canvasRef} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                :
                <Typography>No image</Typography>
            }
        </Box>
    )
}

export default SubjectImage
