export enum OptionsActionType {
    NewOption,
    RemoveOption,
    UpdateOption
}

export interface NewOption {
    type: OptionsActionType.NewOption
}

export interface RemoveOption {
    type: OptionsActionType.RemoveOption,
    index: number
}
export interface UpdateOption {
    type: OptionsActionType.UpdateOption,
    value: string,
    index: number,
    option: "key" | "value"
}

export type OptionActions = NewOption | RemoveOption | UpdateOption;