import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { AppContext } from '../../store/context'
import { showMessage } from '../../store/reducer'

const Message: React.FC = () => {

    const { state, dispatch } = useContext(AppContext)

    const getMessageColor = () => {
        if (state.message.type === 'info') {
            return "white";
        }
        else if (state.message.type === 'success') {
            return "green";
        }
        else if (state.message.type === 'warning') {
            return "yellow";
        }
        return "red";
    }

    const renderMessage = () => {
        if (!state.message.message) return null;
        if (!state.session) {
            setTimeout(() => {
                dispatch(showMessage({ type: 'info', message: '' }))
            }, 3000);
        }
        return (
            <Typography
                align='center'
                fontSize="40pt"
                color={getMessageColor()}
                sx={{
                    textShadow: "-1px 1px 0 #000"
                }}
            >
                {state.message.message}
            </Typography>
        )
    }

    return (
        <Box sx={{
            position: "fixed",
            transform: "translateY(-50%)",
            top: "30vh",
            overflowWrap: "break-word",
            justifyContent: 'center',
            display: 'flex',
        }}>
            {renderMessage()}
        </Box>
    )
}

export default Message
