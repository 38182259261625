import React, { useContext, useState } from 'react'
import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { FVInitParams } from 'neurotec-faceverification-management-client'
import { NavigationContext } from '../../components/Navigation/store/context'
import { closePage } from '../../components/Navigation/store/reducer'
import { fvInitParamsStorage, fvPrefixStorage } from '../../config/const'
import { initialFvParameters, useLocalStorage } from '../../helpers/useLocalStorage'
import { startSession } from '../../helpers/webRTC'
import { AppContext } from '../../store/context'
import { addSession, showMessage } from '../../store/reducer'
import { Operations } from '../../types/FaceVerification'
import { generateSubjectPrefix } from '../../helpers/prefixGenerator'
import { getInitParamsByManagementConfig, getManagementInfo } from '../../helpers/startOperation'

const Enroll: React.FC = () => {

    const { dispatch } = useContext(NavigationContext);
    const appDispatch = useContext(AppContext).dispatch;

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const [subjectId, setSubjectId] = useState("")
    const [options] = useLocalStorage<FVInitParams>(fvInitParamsStorage, initialFvParameters);
    const [prefix] = useLocalStorage(fvPrefixStorage, generateSubjectPrefix());

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            enroll()
        }   
    }

    const enroll = async () => {
        if (!subjectId || /^\s*$/.test(subjectId)) {
            setError("SubjectID cannot be empty")
            return;
        }
        setLoading(true);
        const managementInfo = await getManagementInfo();
        if (!managementInfo) {
            setLoading(false);
            return;
        }
        let initParams: FVInitParams = getInitParamsByManagementConfig(managementInfo, options);
        initParams.subjectId = subjectId;
        if (managementInfo?.prefixMode) {
            initParams.subjectId = prefix + "." + subjectId;
        }
        initParams.type = Operations.ENROLL;
        startSession(Operations.ENROLL, initParams).then(res => {
            appDispatch(addSession(res.data, initParams));
        }).catch(err => {
            console.log(err)
            appDispatch(showMessage({ message: "Unable to create session: " + err.message, type: "error" }))
        }).finally(() => {
            dispatch(closePage())
        });
    }

    return (
        <React.Fragment>
            <DialogTitle>Enroll subject</DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    error={error ? true : false}
                    helperText={error}
                    fullWidth
                    id="subjectId"
                    name="subjectId"
                    label="Subject ID"
                    value={subjectId}
                    onChange={(event) => setSubjectId(event.target.value)}
                    autoFocus
                    onKeyDown={handleKeyDown}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(closePage())}>
                    Close
                </Button>
                <Box style={{ flex: '1 0 0' }} />
                {loading ? (<CircularProgress size="32px" sx={{ marginRight: 2 }} />)
                    :
                    (<Button
                        variant="contained"
                        color="primary"
                        onClick={enroll}
                    >
                        Start
                    </Button>)}
            </DialogActions>
        </React.Fragment>
    )
}

export default Enroll
