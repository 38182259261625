import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { NavigationContext } from './store/context'
import { selectMenu } from './store/reducer'
import styled from '@emotion/styled'
import { ActionType } from './store/actions'


const NavigationItem = styled(Typography)(({ theme }) => ({
    color: "white",
    fontSize: "1.1rem",
    fontWeight: 500,
    textAlign: "center",
    transition: "0.2s",
    ":hover": {
        opacity: 0.5
    }
}));

export interface IMenuItemProps {
    name: string,
    enabled?: boolean,
    component?: React.FC<any>,
    onClick?: () => void
}

const MenuItem: React.FC<IMenuItemProps> = (props) => {

    const { state, dispatch } = useContext(NavigationContext)

    const handleClick = () => {
        if (props.onClick) {
            if (state.isMobile)
                dispatch({ type: ActionType.ToggleDrawer })
            props.onClick();
        } else if (props.component) {
            dispatch(selectMenu(props.component))
        }
    }

    return (
        <React.Fragment>
            {props.enabled === undefined || props.enabled ? (
                <Box onClick={handleClick} sx={{
                    padding: "1.2rem",
                    cursor: "pointer"
                }}>
                    <NavigationItem>
                        {props.name}
                    </NavigationItem>
                </Box>
            ) : null}
        </React.Fragment>
    )
}

export default MenuItem
