import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { FVInitParams } from 'neurotec-faceverification-management-client';
import React, { useReducer } from 'react';
import Message from './components/Message/Message';
import MenuItem from './components/Navigation/MenuItem';
import Navigation from './components/Navigation/Navigation';
import { THEME } from './config';
import { fvInitParamsStorage, fvPrefixStorage } from './config/const';
import { checkLiveness } from './containers/CheckLiveness/CheckLivenes';
import ClearDB from './containers/ClearDB/ClearDB';
import Enroll from './containers/Enroll/Enroll';
import Info from './containers/Info/Info';
import Options from './containers/Options/Options';
import Verify from './containers/Verify/Verify';
import VideoStream from './containers/VideoStream/VideoStream';
import { generateSubjectPrefix } from './helpers/prefixGenerator';
import { initialFvParameters, useLocalStorage } from './helpers/useLocalStorage';
import { AppContext } from './store/context';
import { appReducer } from './store/reducer';
import { initAppState } from './store/state';

const App: React.FC = () => {

  const [options] = useLocalStorage<FVInitParams>(fvInitParamsStorage, initialFvParameters);
  const [state, dispatch] = useReducer(appReducer, { ...initAppState, options });
  const [prefix] = useLocalStorage(fvPrefixStorage, generateSubjectPrefix());

  const clearDb = () => {
    return (
      <ClearDB prefix={prefix} />
    )
  }

  return (
    <ThemeProvider theme={THEME}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <AppContext.Provider value={{ state, dispatch }}>
          <Navigation>
            <MenuItem name="Enroll" component={Enroll} />
            <MenuItem name="Verify" component={Verify} />
            <MenuItem name="Check Liveness & ICAO" onClick={() => checkLiveness(state.options, dispatch)} />
            <MenuItem name="Clear" component={clearDb} />
            <MenuItem name="Options" component={Options} />
            <MenuItem name="Info" component={Info} />
          </Navigation>
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "stretch", alignContent: "center", overflow: "hidden" }}>
            <VideoStream />
            <Message />
          </Box>
        </AppContext.Provider>
      </Box >
    </ThemeProvider >
  );
}

export default App;
