import { useContext, useEffect, useState } from "react";
import { NavigationContext } from "../../../components/Navigation/store/context";
import { getInitParamsByManagementConfig, getManagementInfo } from "../../../helpers/startOperation";
import { FVInitParams } from "neurotec-faceverification-management-client";
import { initialFvParameters, useLocalStorage } from "../../../helpers/useLocalStorage";
import { fvInitParamsStorage } from "../../../config/const";
import { AppContext } from "../../../store/context";
import { startSession } from "../../../helpers/webRTC";
import { Operations } from "../../../types/FaceVerification";
import { addSession, setOptions, showMessage } from "../../../store/reducer";
import { closePage } from "../../../components/Navigation/store/reducer";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl } from "@mui/material";
import React from "react";

interface IVerifyFromQrCodeProps {
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const VerifyFromQrCode: React.FC<IVerifyFromQrCodeProps> = (props) => {

    const navigationDispatch = useContext(NavigationContext).dispatch;
    const { state, dispatch } = useContext(AppContext)
    const [loading, setLoading] = useState(false);
    const [options] = useLocalStorage<FVInitParams>(fvInitParamsStorage, initialFvParameters);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        const initShow = () => {
            setShow(props.show)
        }
        initShow()
    }, [props])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleButtonPress()
        }  
    }

    const handleButtonPress = () => {
        verifyFromTemplate()
        setShow(false)
    }

    const handleClose = () => {
        setShow(false)
        props.setShow(false)
    }

    const verifyFromTemplate = async () => {
        if (!state.options?.verificationTemplate)
            return;
        
        setLoading(true);
        const managementInfo = await getManagementInfo();
        if (!managementInfo) {
            setLoading(false);
            return;
        }

        let initParams: FVInitParams = getInitParamsByManagementConfig(managementInfo, options);
        initParams.verificationTemplate = state.options?.verificationTemplate;
        initParams.type = Operations.VERIFY_WITH_QR;
        startSession(Operations.VERIFY_WITH_QR, initParams).then(res => {
            dispatch(addSession(res.data, initParams));
        }).catch(err => {
            console.log(err)
            dispatch(showMessage({ message: "Unable to create session: " + err.message, type: "error" }))
        }).finally(() => {
            setLoading(false);
            setShow(false)
            props.setShow(false)
            navigationDispatch(closePage())
        });
    }

    return (
        <Dialog
            open={(state.options?.verificationTemplate !== undefined && state.options?.verificationTemplate?.length > 0) && show}
            onClose={handleClose}
            fullWidth={true}
            onKeyDown={handleKeyDown}
        >
            <DialogTitle>Template found</DialogTitle>
            <DialogContent>
                <FormControl style={{width: "100%"}}>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setOptions({verificationTemplate: undefined}))}>
                    Close
                </Button>
                <Box style={{ flex: '1 0 0' }} />
                {loading ? (<CircularProgress size="32px" sx={{ marginRight: 2 }} />)
                    :
                    (<Button
                        variant="contained"
                        color="primary"
                        onClick={handleButtonPress}
                    >
                        Start
                    </Button>)}
            </DialogActions>
        </Dialog>
    )
}

export default VerifyFromQrCode
