import React from "react";
import { FVInitParams } from "neurotec-faceverification-management-client";
import { startSession } from "../../helpers/webRTC";
import { AppActions } from "../../store/actions";
import { addSession, showMessage } from "../../store/reducer";
import { Operations } from "../../types/FaceVerification";
import { getInitParamsByManagementConfig, getManagementInfo } from "../../helpers/startOperation";

export const checkLiveness = async (options: FVInitParams | null, appDispatch: React.Dispatch<AppActions>) => {
    if (options) {
        appDispatch(showMessage({ message: "Loading", type: "info" }))
        const managementInfo = await getManagementInfo();
        if (!managementInfo) {
            appDispatch(showMessage({ message: "Could not get management info", type: "error" }))
            return;
        }
        let initParams = getInitParamsByManagementConfig(managementInfo, options);
        initParams.type = Operations.CHECK_LIVENESS;
        startSession(Operations.CHECK_LIVENESS, initParams).then(res => {
            appDispatch(addSession(res.data, initParams));
        }).catch(err => {
            console.log(err)
            appDispatch(showMessage({ message: "Unable to create session: " + err.message, type: "error" }))
        });
    } else {
        appDispatch(showMessage({ message: "Options must be specified", type: "error" }));
    }
}