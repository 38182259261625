import React from 'react'
import { IconButton, styled } from '@mui/material';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MenuContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflow: "scroll",
    zIndex: 10,
    backgroundColor: theme.palette.primary.main,
    minHeight: "520px"
}));

interface IMobileNavigationProps {
    onNavClose: () => void
}

const MobileNavigation: React.FC<IMobileNavigationProps> = (props) => {

    return (
        <MenuContainer>
            <IconButton onClick={() => props.onNavClose()} sx={{ color: "white", alignSelf: "flex-end", margin: "2rem" }}>
                <CloseIcon fontSize="large" />
            </ IconButton>
            <Box sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
            }}>
                {props.children}
            </Box>
        </MenuContainer>
    )
}

export default MobileNavigation
