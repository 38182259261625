import { FVInitParams, SessionData } from "neurotec-faceverification-management-client";
import { IMessage } from "./actions";

export interface AppState {
    session: SessionData | null,
    options: FVInitParams | null,
    showNav: boolean,
    showOperationSummary: string,
    showQrCode: string,
    qrSession: boolean,
    message: IMessage
}

export const initAppState: AppState = {
    session: null,
    showNav: true,
    showOperationSummary: "",
    showQrCode: "",
    qrSession: false,
    message: {
        message: '',
        type: "info"
    },
    options: null
}